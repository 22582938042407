import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import calendarReducer from "../features/calendar/calendarSlice";
import eventReducer from "../features/events/eventSlice";
import groupReducer from "../features/groups/groupSlice";
import holidayReducer from "../features/holidays/holidaySlice";
import menuReducer from "../features/menu/menuSlice";
import scheduleReducer from "../features/schedule/scheduleSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    schedules: scheduleReducer,
    calendars: calendarReducer,
    events: eventReducer,
    holidays: holidayReducer,
    menu: menuReducer,
    groups: groupReducer,
  },
});
