import { createEvents } from "ics";
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Dropdown, Header, Menu, Segment, Table } from "semantic-ui-react";
import GeneratePDF from "../components/generatePDF";
import { getAll } from "../features/calendar/calendarSlice";
import { getAllEventsFromCalendar } from "../features/events/eventSlice";
import { getAllGroups } from '../features/groups/groupSlice';

const CalendarPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [timezone, setTimezone] = useState("MT");

  const timezoneMapping = {
    MT: 'America/Denver',
    PT: 'America/Los_Angeles',
    CT: 'America/Chicago',
    ET: 'America/New_York',
    AT: 'America/Halifax'
  };

  const { calendarsArr, isLoading: isLoadingCal } = useSelector(
    (state) => state.calendars
  );

  const { eventsArr, isLoading: isLoadingEvn } = useSelector((state) => state.events);
  const { groupsArr, isLoading: isLoadingGrp } = useSelector((state) => state.groups);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getAllEventsFromCalendar(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getAll());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllGroups());
  }, [dispatch]);


  const calendar = useMemo(() => {
    return calendarsArr.find((calendar) => calendar?.id === id);
  }, [calendarsArr, id]);

  const events = [...eventsArr]

  const group = useMemo(() => {
    return groupsArr.find((group) => group?.id === calendar?.groupid);
  }, [groupsArr, calendar]);

  const createICSLink = async () => {
    // Transform each event into the structure expected by createEvents
    const eventArray = events.map(event => {
      const startDateTime = new Date(event.startdate);
      const startTime = event.starttime.split(':');
      const endTime = event.endtime.split(':');
  
      // Adjust the startDateTime with the event's start time
      startDateTime.setHours(Number(startTime[0]), Number(startTime[1]), Number(startTime[2]));
  
      // Calculate event duration in minutes
      const durationMinutes = (Number(endTime[0]) - Number(startTime[0])) * 60 + (Number(endTime[1]) - Number(startTime[1]));
  
      return {
        title: event.activity,
        start: [startDateTime.getFullYear(), startDateTime.getMonth() + 1, startDateTime.getDate(), startDateTime.getHours(), startDateTime.getMinutes()],
        duration: { minutes: durationMinutes },
        description: event.topic,
      };
    });
  
    try {
      const filename = `${calendar.title}.ics`; // Adjust this line if calendar structure is different
      const { value } = await new Promise((resolve, reject) => {
        createEvents(eventArray, (error, value) => {
          if (error) {
            reject(error);
          } else {
            resolve({ value });
          }
        });
      });
  
      const file = new Blob([value], { type: 'text/calendar' });
      const url = URL.createObjectURL(file);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = filename;
      document.body.appendChild(anchor);
      anchor.click();
  
      setTimeout(() => { // Ensure file has been downloaded before revoking URL and removing anchor
        document.body.removeChild(anchor);
        URL.revokeObjectURL(url);
      }, 100);
    } catch (error) {
      console.error('Failed to create ICS link:', error);
    }
  };

  const timeOptions = useMemo(() => {
    return [
      { key: "MT", text: "Mountain Time", value: "MT" },
      { key: "PT", text: "Pacific Time", value: "PT" },
      { key: "CT", text: "Central Time", value: "CT" },
      { key: "ET", text: "Eastern Time", value: "ET" },
      { key: "AT", text: "Atlantic Time", value: "AT"}
    ];
  }, []);

  const convertTimezone = (date, time, targetTimezone) => {
    const dateTimeInMT = DateTime.fromISO(`${date}T${time}`, { zone: 'America/Denver' });
    const dateTimeInTargetZone = dateTimeInMT.setZone(timezoneMapping[targetTimezone]);
    return dateTimeInTargetZone.toFormat('HH:mm:ss');
  };
  
  
  const renderRows = () => {
    return events.length > 0 ? (
      events.sort((a, b) => {
        // First, compare by event start date using Luxon
        const dateA = DateTime.fromISO(a.startdate).setZone('America/Denver');
        const dateB = DateTime.fromISO(b.startdate).setZone('America/Denver');
        const dateComparison = dateA - dateB;
        if (dateComparison !== 0) return dateComparison;

        // If the dates are the same, compare by event start time using Luxon
        const timeA = DateTime.fromISO(`${a.startdate.split('T')[0]}T${a.starttime}`).setZone('America/Denver');
        const timeB = DateTime.fromISO(`${b.startdate.split('T')[0]}T${b.starttime}`).setZone('America/Denver');
        const timeComparison = timeA - timeB;
        if (timeComparison !== 0) return timeComparison;

        return 0;
      }).map((event) => {
        const rowClasses = [
          event.topic === "" && "QA",
          event.activity.includes("Coach") && "coach",
          event.activity.includes("Group Meeting") && "meeting",
          event.activity.includes("Guest") && "guest",
          event.activity.includes("Certificate") && "certificate"
        ].filter(Boolean).join(" ");

        const startTime = convertTimezone(event.startdate.split('T')[0], event.starttime, timezone);
        const endTime = convertTimezone(event.startdate.split('T')[0], event.endtime, timezone);
  
        return (
          <Table.Row key={event.id} className={rowClasses}>
            <Table.Cell singleLine>{DateTime.fromISO(event.startdate).toFormat('ccc, MMM dd, yyyy')}</Table.Cell>
            <Table.Cell singleLine>{startTime} - {endTime}</Table.Cell>
            <Table.Cell>{event.activity}</Table.Cell>
            <Table.Cell>{event.duration}</Table.Cell>
            <Table.Cell>{event.topic}</Table.Cell>
          </Table.Row>
        );
      })
    ) : null;
  };

  return (
    <Container>
      <Segment loading={isLoadingCal || isLoadingEvn || isLoadingGrp || !calendar || loading} basic>
        <Header as="h1" textAlign="center">
          {calendar?.title.charAt(0).toUpperCase() + calendar?.title.slice(1)} Calendar
          <Header.Subheader>
            {DateTime.fromISO(calendar?.startdate).toFormat('cccc, MMMM dd, yyyy')}
            {" - "}
            {DateTime.fromISO(calendar?.enddate).toFormat('cccc, MMMM dd, yyyy')}
          </Header.Subheader>
          <Header.Subheader>
            Assigned Group: {group?.groupname}
          </Header.Subheader>
        </Header>
        <Segment>
          <Menu size="large" pagination stackable widths={5} >
            <Dropdown item text={`Timezone: ${timezone}`} value={timezone} >
              <Dropdown.Menu>
                {
                  timeOptions.map((option) => (
                    <Dropdown.Item key={option.key} {...option} onClick={() => setTimezone(option.value)} />
                  ))
                }
              </Dropdown.Menu>
            </Dropdown>
            <Menu.Item onClick={() => navigate(`/${id}/edit`)}>Edit Calendar</Menu.Item>
            <Menu.Item onClick={() => createICSLink()}>Export to ICS</Menu.Item>
            <Menu.Item onClick={() => GeneratePDF(calendar, events, timezone, setLoading)}>Download PDF</Menu.Item>
            <Menu.Item onClick={() => navigate(`/${id}/confirm`)}>Delete Calendar</Menu.Item>
          </Menu>
        </Segment>
        <Segment secondary padded>
          {/* add events table has date time activity durtation and topics */}
          <Table stackable textAlign="center"  size="large">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Time in {timezone}</Table.HeaderCell>
                <Table.HeaderCell>Activity</Table.HeaderCell>
                <Table.HeaderCell>Duration</Table.HeaderCell>
                <Table.HeaderCell>Topic</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                renderRows()
              }
            </Table.Body>
          </Table>
        </Segment>
      </Segment>
    </Container>
  );
};
export default CalendarPage;
