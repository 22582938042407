import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Confirm from "./components/Confirm";
import HolidayConfirm from "./components/HolidayConfirm";
import Navbar from "./components/Navbar";
import PrivateRoutes from "./features/utils/PrivateRoutes";
import CalendarPage from "./pages/CalendarPage";
import CreateCalendars from "./pages/CreateCalendars";
import Dashboard from "./pages/Dashboard";
import EditCalendar from "./pages/EditCalendar";
import EditHolidayForm from "./pages/EditHolidayForm";
import ErrorPage from "./pages/ErrorPage";
import HolidayDashboard from "./pages/HolidayDashboard";
import HolidayForm from "./pages/HolidayForm";
import Login from "./pages/Login";
import ViewCalendar from "./pages/ViewCalendar";
import "./styles/app.scss";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/view-calendar/:id" element={<ViewCalendar />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/create-schedule" element={<CreateCalendars />} />
            <Route path="/:id" element={<CalendarPage />}></Route>
            <Route path="/:id/confirm" element={<Confirm />}></Route>
            <Route path="/:id/edit" element={<EditCalendar />} />
            <Route path="/holidays" element={<HolidayDashboard />} />
            <Route path="/holidays/create" element={<HolidayForm />} />
            <Route path="/holidays/:holidayID/confirm"element={<HolidayConfirm />} />
            <Route path="/holidays/:holidayID/edit" element={<EditHolidayForm />} />
          </Route>
          <Route path="/error" element={<ErrorPage />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
