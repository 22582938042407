import axios from "axios";

let API_URL = "";
if (process.env.NODE_ENV !== "production") {
  API_URL = "http://localhost:5000/api/calendars/"; // lets us use the backend server in local development
} else {
  API_URL = "/api/calendars/"
}

// may also want to take in cookie validation
const createCalendar = async (calendarData) => {
  const { data } = await axios.post(API_URL, calendarData);
  return data;
};

// GET all calendars
const getAll = async () => {
  const { data } = await axios.get(API_URL);
  return data;
};

// GET one calendar
const getOneCalendar = async (calendarID) => {
  const { data } = await axios.get(API_URL + calendarID);
  return data;
};

//Edit Calendar
const editCalendar = async (calendarID, calendarData) => {
  const { data } = await axios.put(API_URL + calendarID, calendarData);
  return data;
};

//Delete Calendar
const deleteCalendar = async (calendarId) => {
  const { data } = await axios.delete(API_URL + calendarId);
  return data;
};

const calendarService = {
  createCalendar,
  getAll,
  getOneCalendar,
  editCalendar,
  deleteCalendar,
};

export default calendarService;
