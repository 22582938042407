import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Button, Container, Header, Segment, Table } from "semantic-ui-react";
import { getAll } from "../features/calendar/calendarSlice";
import "../styles/dashboard.scss";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { calendarsArr, isLoading } = useSelector(
    (state) => state.calendars
  );

  const [sortColumn, setSortColumn] = useState('title');
  const [sortDirection, setSortDirection] = useState('descending');

  useEffect(() => {
    dispatch(getAll());
  }, [dispatch]);

  const handleSort = (clickedColumn) => () => {
    if (sortColumn !== clickedColumn) {
      setSortColumn(clickedColumn);
      setSortDirection("ascending");
      return;
    }
    setSortDirection(sortDirection === "ascending" ? "descending" : "ascending");
  }

  const sortedCalendars = useMemo(() => {
    let sortedData = [...calendarsArr];
    if (sortColumn && sortDirection) {
      sortedData.sort((a, b) => {
        if (sortColumn === "start_date" || sortColumn === "end_date") {
          const dateA = new Date(a[sortColumn]).getTime();
          const dateB = new Date(b[sortColumn]).getTime();
          return sortDirection === "ascending" ? dateA - dateB : dateB - dateA;
        }
        if (a[sortColumn] < b[sortColumn]) {
          return sortDirection === "ascending" ? -1 : 1;
        }
        if (a[sortColumn] > b[sortColumn]) {
          return sortDirection === "ascending" ? 1 : -1;
        }
        
        return 0;
      });
    }
    return sortedData;
  }, [calendarsArr, sortColumn, sortDirection]);

  return (
    <Container textAlign="center">
      <Segment basic>
        <Header as="h1" textAlign="center"> Calendar Generator </Header>
        <Segment padded secondary loading={isLoading}>
          {
            calendarsArr.length > 0 ? (
              <Table stackable textAlign="center" singleLine compact size="large" sortable celled>
                <Table.Header>
                  <Table.Row >
                    <Table.HeaderCell sorted={sortColumn === "title" ? sortDirection : null} onClick={handleSort("title")}>Title</Table.HeaderCell>
                    <Table.HeaderCell sorted={sortColumn === "start_date" ? sortDirection : null} onClick={handleSort("start_date")}>Start Date</Table.HeaderCell>
                    <Table.HeaderCell sorted={sortColumn === "end_date" ? sortDirection : null} onClick={handleSort("end_date")}>End Date</Table.HeaderCell>
                    <Table.HeaderCell sorted={sortColumn === "province" ? sortDirection : null} onClick={handleSort("province")}>Province</Table.HeaderCell>
                    <Table.HeaderCell>
                      <Button fluid  size="big" color="green" onClick={() => navigate("/create-schedule")}> Create Schedule </Button>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {sortedCalendars.map((calendar) => (
                    <Table.Row key={calendar.id}>
                      <Table.Cell>{calendar.title?.charAt(0).toUpperCase() + calendar.title?.slice(1)}</Table.Cell>
                      <Table.Cell>{DateTime.fromISO(calendar.startdate).setZone('America/Denver').toFormat('cccc, MMMM dd, yyyy')}</Table.Cell>
                      <Table.Cell>{DateTime.fromISO(calendar.enddate).setZone('America/Denver').toFormat('cccc, MMMM dd, yyyy')}</Table.Cell>
                      <Table.Cell>{calendar.province}</Table.Cell>
                      <Table.Cell>
                        <Button fluid size="small" as={Link} to={`/${calendar.id}`} color="blue"> View </Button>
                      </Table.Cell>
                    </Table.Row>
                    ))
                  }
                </Table.Body>

              </Table>
            ) : (
              <Header as="h2" textAlign="center"> No Calendars Found </Header>
            )
          }
        </Segment>
      </Segment>
    </Container>
  );
};
export default Dashboard;
