import axios from "axios";


let API_URL = "";
if (process.env.NODE_ENV !== "production") {
    API_URL = "http://localhost:5000/api"; // lets us use the backend server in local development
} else {
    API_URL = "/api"
}


const getDefaultSchedule = async () => {
    const { data } = await axios.get(`${API_URL}/getdefaultschedule`);
    return data;
}

const scheduleService = {
    getDefaultSchedule
}

export default scheduleService;