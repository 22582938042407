import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Form, Header, Segment } from "semantic-ui-react";
import { v4 as uuidv4 } from "uuid";
import {
  createHoliday,
  getAllHolidays,
} from "../features/holidays/holidaySlice";

const HolidayForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [holidayData, setHolidayData] = useState({
    holidayID: uuidv4(),
    holidayStart: "",
    holidayEnd: "",
    holidayTitle: "",
    category: "Personal",
  });

  const { holidayID, holidayStart, holidayEnd, holidayTitle, category } = holidayData;


  const onSubmit = (e) => {
    e.preventDefault();
    const canSave = [holidayID, holidayStart, holidayEnd, holidayTitle, category].every(
      (el) => el.length >= 1
    );
    if (canSave) {
      try {
        dispatch(
          createHoliday({
            holidayID,
            holidayStart,
            holidayEnd,
            holidayTitle,
            category,
          })
        );
        toast.success("Holiday successfully created");
        dispatch(getAllHolidays());
        setHolidayData({
          holidayID: uuidv4(),
          holidayStart: "",
          holidayEnd: "",
          holidayTitle: "",
          category: "Personal",
        });
        navigate("/holidays");
      } catch (error) {
        const message =
          error.response.data.message || error.message || error.toString();
        toast.error(message);
        console.log(message);
      }
    }
  };

  return (
    <Container>
      <Segment padded raised  size="huge">
        <Header dividing as="h2" textAlign="center"> Create Holiday </Header>
        <Form onSubmit={onSubmit}>
          <Form.Input
            label="Title"
            placeholder="Enter Holiday Title"
            name="holidayTitle"
            value={holidayTitle}
            onChange={(e) =>
              setHolidayData({ ...holidayData, holidayTitle: e.target.value })
            }
            required
          />
          <Form.Input
            label="Start Date"
            type="date"
            name="holidayStart"
            value={holidayStart}
            onChange={(e) =>
              setHolidayData({ ...holidayData, holidayStart: e.target.value })
            }
            required
          />
          <Form.Input
            label="End Date"
            type="date"
            name="holidayEnd"
            value={holidayEnd}
            onChange={(e) =>
              setHolidayData({ ...holidayData, holidayEnd: e.target.value })
            }
            required
          />
          <Form.Select
            label="Category"
            name="category"
            value={category}
            onChange={(e, { value }) =>
              setHolidayData({ ...holidayData, category: value })
            }
            options={[
              { key: "federal", text: "Federal", value: "Federal" },
              { key: "provincial_on", text: "Provincial (ON)", value: "Provincial-ON" },
              { key: "provincial_ab", text: "Provincial (AB)", value: "Provincial-AB" },
              { key: "jewish", text: "Jewish", value: "Jewish" },
              { key: "personal", text: "Personal", value: "Personal" },
            ]}
            required
          />
          <Form.Group widths="equal">
            <Form.Button color="green" fluid size="large"> Create Holiday </Form.Button>
            <Form.Button color="red" fluid size="large" onClick={() => navigate("/holidays")}> Cancel </Form.Button>
          </Form.Group>
        </Form>
      </Segment>
    </Container>
  );
};
export default HolidayForm;
