import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import calendarService from "./calendarService";

const initialState = {
  calendarsArr: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Create new Calendar
export const createCalendar = createAsyncThunk(
  "calendars/create",
  async (calendarData, thunkAPI) => {
    try {
      // should also pass in cookie informarion
      return await calendarService.createCalendar(calendarData);
    } catch (error) {
      const message = error.response.data.message || error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// GET All Calendars
export const getAll = createAsyncThunk(
  "calendars/getAll",
  async (_, thunkAPI) => {
    try {
      // should also pass in cookie informarion
      return await calendarService.getAll();
    } catch (error) {
      const message = error.response.data.message || error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Get individual calendar
export const getOneCalendar = createAsyncThunk(
  "calendars/getOne",
  async (calendarsId, thunkAPI) => {
    try {
      return await calendarService.getOneCalendar(calendarsId);
    } catch (error) {
      const message =
        error.response.data.message || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update/Edit calendar
export const editCalendar = createAsyncThunk(
  "calendars/edit",
  async (calendarData, thunkAPI) => {
    const { id } = calendarData;

    try {
      const response = await calendarService.editCalendar(
        id,
        calendarData
      );
      return response;
    } catch (error) {
      const message =
        error.response.data.message || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// delete calendar
export const deleteCalendar = createAsyncThunk(
  "calendars/delete",
  async (id, thunkAPI) => {
    try {
      return await calendarService.deleteCalendar(id);
    } catch (error) {
      const message =
        error.response.data.message || error.message || error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  // this will delete everything in the calendars array
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      // create Schedule
      .addCase(createCalendar.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createCalendar.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.calendarsArr.push(action.payload);
      })
      .addCase(createCalendar.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      //Get All
      .addCase(getAll.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAll.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = "";
        state.calendarsArr = action.payload;
      })
      .addCase(getAll.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        state.calendarsArr = [];
      })

      //Get One
      .addCase(getOneCalendar.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOneCalendar.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.calendarsArr = action.payload;
      })
      .addCase(getOneCalendar.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.calendarsArr = [];
      })

      // Update / Edit
      .addCase(editCalendar.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editCalendar.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.calendarsArr = state.calendarsArr.map((calendar) =>
          calendar.id === action.payload.id ? action.payload : calendar
        );
      })
      .addCase(editCalendar.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      //Delete
      .addCase(deleteCalendar.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteCalendar.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.calendarsArr = state.calendarsArr.filter(
          (calendar) => calendar.id !== action.payload.id
        );
      })
      .addCase(deleteCalendar.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = calendarSlice.actions;

export default calendarSlice.reducer;
