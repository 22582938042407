import axios from "axios";

// const HOLIDAY_API_URL = "http://localhost:8080/api/holidays/";
// ENTER THE YEAR WHERE YOU WANT TO GET THE HOLIDAYS. MOSt CURRENT UPDATE IS UP TO 2027

let HOLIDAY_API_URL = "";
if (process.env.NODE_ENV !== "production") {
  HOLIDAY_API_URL = "http://localhost:5000/api/holidays/"; // lets us use the backend server in local development
} else {
  HOLIDAY_API_URL = "/api/holidays/";
}

const nationalHolidaysAPI =
  "https://canada-holidays.ca/api/v1/holidays?year=2027";

// may also want to take in cookie validation
const createHoliday = async (holidayData) => {
  const { data } = await axios.post(`${HOLIDAY_API_URL}`, holidayData);

  return data;
};

// GET one holiday
const getOneHoliday = async (holidayID) => {
  const { data } = await axios.get(`${HOLIDAY_API_URL}${holidayID}`);
  return data;
};

// GET all holidays
const getAllHolidays = async () => {
  const { data } = await axios.get(`${HOLIDAY_API_URL}`);
  return data;
};

// GET all holidays
const getAllHolidaysFromAPI = async () => {
  const { data } = await axios.get(`${nationalHolidaysAPI}`);
  return data.holidays;
};

//Edit holiday
const editHoliday = async (holidayID, holidayData) => {
  const { data } = await axios.put(
    `${HOLIDAY_API_URL}${holidayID}`,
    holidayData
  );
  return data;
};

//Delete event
const deleteHoliday = async (id) => {
  const { data } = await axios.delete(`${HOLIDAY_API_URL}${id}`);
  return data;
};

//Delete All Events From Specific calendar
const deleteAllHolidays = async () => {
  const { data } = await axios.delete(`${HOLIDAY_API_URL}`);
  return data;
};

const calendarService = {
  createHoliday,
  getOneHoliday,
  getAllHolidays,
  getAllHolidaysFromAPI,
  editHoliday,
  deleteHoliday,
  deleteAllHolidays,
};

export default calendarService;
