import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import holidayService from "./holidayService";

const initialState = {
  holidaysArr: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Create new Event
export const createHoliday = createAsyncThunk(
  "holidays/create",
  async (holidayData, thunkAPI) => {
    try {
      // should also pass in cookie informarion
      return await holidayService.createHoliday(holidayData);
    } catch (error) {
      const message = error.response.data.message || error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Get individual holiday
export const getOneHoliday = createAsyncThunk(
  "holidays/getOne",
  async (holidayID, thunkAPI) => {
    try {
      return await holidayService.getOneHoliday(holidayID);
    } catch (error) {
      const message =
        error.response.data.message || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// GET All holidays
export const getAllHolidays = createAsyncThunk(
  "holidays/getAll",
  async (_, thunkAPI) => {
    try {
      // should also pass in cookie informarion
      return await holidayService.getAllHolidays();
    } catch (error) {
      const message = error.response.data.message || error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// GET All holidays
export const getAllHolidaysFromAPI = createAsyncThunk(
  "holidays/getAllFromAPI",
  async (_, thunkAPI) => {
    try {
      // should also pass in cookie informarion
      return await holidayService.getAllHolidaysFromAPI();
    } catch (error) {
      const message = error.response.data.message || error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update/Edit Holiday
export const editHoliday = createAsyncThunk(
  "holidays/edit",
  async (holidayData, thunkAPI) => {
    const id = holidayData.holidayID;
    try {
      return await holidayService.editHoliday(id, holidayData);
    } catch (error) {
      const message =
        error.response.data.message || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// delete Holiday
export const deleteHoliday = createAsyncThunk(
  "holidays/delete",
  async (id, thunkAPI) => {
    try {
      return await holidayService.deleteHoliday(id);
    } catch (error) {
      const message =
        error.response.data.message || error.message || error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// delete All Events From a specific calendar
export const deleteAllHolidays = createAsyncThunk(
  "holidays/deleteAll",
  async (_, thunkAPI) => {
    try {
      return await holidayService.deleteAllHolidays();
    } catch (error) {
      const message =
        error.response.data.message || error.message || error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const holidaySlice = createSlice({
  name: "holiday",
  initialState,
  // this will delete everything in the holidays array
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      // create holiday
      .addCase(createHoliday.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createHoliday.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.holidaysArr.push(action.payload);
      })
      .addCase(createHoliday.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      //Get One
      .addCase(getOneHoliday.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOneHoliday.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = "";
        state.holidaysArr = action.payload;
      })
      .addCase(getOneHoliday.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        state.holidaysArr = [];
      })

      //Get All
      .addCase(getAllHolidays.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllHolidays.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = "";
        state.holidaysArr = action.payload;
      })
      .addCase(getAllHolidays.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        state.holidaysArr = [];
      })

      //Get All from api
      .addCase(getAllHolidaysFromAPI.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllHolidaysFromAPI.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = "";
        state.holidaysArr = action.payload;
      })
      .addCase(getAllHolidaysFromAPI.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        state.holidaysArr = [];
      })

      // Update / Edit
      .addCase(editHoliday.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editHoliday.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        if (!action.payload?.holidayID) {
          console.log("action payload", action.payload);
          return console.log("could not update post");
        }
        const { holidayID } = action.payload;
        const holidays = state.holidaysArr.filter(
          (holiday) => holiday.holidayID !== holidayID
        );
        state.holidaysArr = [...holidays, action.payload];
      })
      .addCase(editHoliday.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      //Delete
      .addCase(deleteHoliday.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteHoliday.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.holidaysArr = state.holidaysArr.filter(
          (holiday) => holiday.holidayID !== action.payload.id
        );
      })
      .addCase(deleteHoliday.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      // Delete ALL
      //Delete ALl from Calendar
      .addCase(deleteAllHolidays.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAllHolidays.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.holidaysArr = [];
      })
      .addCase(deleteAllHolidays.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = holidaySlice.actions;

export default holidaySlice.reducer;
