import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Form, Header, Menu, Segment, Table } from 'semantic-ui-react';
import { v4 as uuidv4 } from "uuid";
import EventRows from "../components/EventsRow";
import { editCalendar, getAll as getAllCalendars, reset as resetCalendar } from "../features/calendar/calendarSlice";
import { deleteEvent, editEvent, getAllEventsFromCalendar, reset as resetEvents } from "../features/events/eventSlice";
import "../styles/events.scss";

const EditCalendar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const { calendarsArr, isLoading: isLoadingCal } = useSelector((state) => state.calendars);
  const { eventsArr, isLoading: isLoadingEvn } = useSelector((state) => state.events);

  useEffect(() => {
    dispatch(getAllCalendars());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllEventsFromCalendar(id));
  }, [dispatch, id]);

  //the following code block ensures that all the event and calendar data is loaded before proceeding, even if the page is refreshed
  //necessary unlike the other pages because of issues of data not being loaded after refresh

  const selectedCld = useMemo(() => {
    return calendarsArr.find((calendar) => calendar.id === id);
  }, [calendarsArr, id]);

  const [calendar, setCalendar] = useState({
    id: '',
    title: '',
    startdate: '',
    enddate: '',
    timezone: 'MT',
    province: '',
  });

 useEffect(() => {
    if (selectedCld) {
      setCalendar({
        ...selectedCld,
        startdate: selectedCld?.startdate.split("T")[0],
        enddate: selectedCld?.enddate.split("T")[0],
      });
    }
  }, [selectedCld]);

  let { title, startdate, enddate, timezone, province } = calendar;

  const [eventsData, setEventsData] = useState([]);
  
  const events = [...eventsArr]

  const transformedEvents = useMemo(() => {
    return events
      .sort((a, b) => {
        // First, compare by event start date
        const dateComparison = new Date(a.startdate) - new Date(b.startdate);
        if (dateComparison !== 0) return dateComparison;

        // If the dates are the same, compare by event start time
        const timeA = a.starttime.split(':').map(Number);
        const timeB = b.starttime.split(':').map(Number);
        const hourComparison = timeA[0] - timeB[0];
        if (hourComparison !== 0) return hourComparison;

        // If the hours are the same, compare by minutes
        return timeA[1] - timeB[1];
      })
      .map((event) => ({
        ...event,
        startdate: event.startdate.split("T")[0],
      }));
  }, [eventsArr, id]);
  
  useEffect(() => {
    setEventsData(transformedEvents);
  }, [transformedEvents]);

  const [verifyHolidays, setVerifyHolidays] = useState([]);
  const [activeRow, setActiveRow] = useState(null);
  const [conflict, setConflict] = useState(false);
  const [loading, setLoading] = useState(false);

  const createEventRow = () => {
    
    const newEvent = {
      id: uuidv4(),
      calendarid: id, 
      startdate: new Date().toISOString().split("T")[0],
      starttime: "09:00",
      endtime: "10:00",
      activity: "New Activity",
      topic: "New Topic", 
      duration: "1 hour", 
    };
  
   
    setEventsData(prevEventsData => [...prevEventsData, newEvent]);
    setActiveRow(newEvent.id);
    toast.success("New event created", { autoClose: 1000 });
  };

  const checkLetters = (word) => {
    return /^\s*([a-zA-Z0-9 !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*)\s*$/.test(word);
  };

  const validation = () => {
    if (eventsData.length === 0) {
      toast.error("No events to generate schedule");
      return false;
    }
  
    if (calendar.title === "" || !checkLetters(calendar.title)) {
      toast.error("Please enter a valid title");
      return false;
    }
  
    if (conflict) {
      toast.error("Please resolve all conflicts before generating schedule");
      return false;
    }
  
    return true;
  }

  const deleteRemovedEvents = async () => {
    const calendarid = id;

    const eventIDsInDatabase = eventsArr.filter(event => event.calendarid === id).map(event => event.id);
    const eventIDsInUpdatedData = eventsData.map(event => event.id);

    const removedEvents = eventIDsInDatabase.filter(id => !eventIDsInUpdatedData.includes(id));

    if (removedEvents.length > 0) {
      await Promise.all(removedEvents.map(id => dispatch(deleteEvent({ calendarid: calendarid, id: id })).unwrap()));
    }
  };

  console.log(eventsData);
  
  const updateCalendar = async () => {
    if (!validation()) return;

    try {
      setLoading(true);
      await deleteRemovedEvents();
      await Promise.all(eventsData.map(event => dispatch(editEvent(event)).unwrap()));
      await dispatch(editCalendar(calendar)).unwrap();
      toast.success("Schedule updated successfully", { autoClose: 1000 });
    } catch (error) {
      setLoading(false);
      console.log("Error updating schedule", error);
    } finally {
      setLoading(false);
      dispatch(resetCalendar());
      dispatch(resetEvents());
      navigate(`/${id}`);
    }
  }

  return (
    <Container>
      <Segment loading={isLoadingCal || isLoadingEvn || loading} basic>
        <Header as="h1" textAlign="center"> Edit Schedule
          <Header.Subheader>Calendar ID: {id}</Header.Subheader>
        </Header>
        <Segment>
          <Form size="large">
            <Form.Input
              label="Title"
              placeholder="Title"
              name="title"
              value={title}
              onChange={(e) => setCalendar({ ...calendar, [e.target.name]: e.target.value })}
            />
            <Form.Group widths="equal">
              
              <Form.Input
                label="Start Date"
                type="date"
                name="startdate"
                value={startdate}
                onChange={(e) => setCalendar({ ...calendar, [e.target.name]: e.target.value })}
              />
              <Form.Input
                label="End Date"
                type="date"
                name="enddate"
                value={enddate}
                onChange={(e) => setCalendar({ ...calendar, [e.target.name]: e.target.value })}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Dropdown
                label="Province"
                placeholder="Province"
                name="province"
                value={province}
                onChange={(e, { value }) => setCalendar({ ...calendar, province: value })}
                selection
                options={[
                  { key: "AB", text: "Alberta", value: "AB" },
                  { key: "BC", text: "British Columbia", value: "BC" },
                  { key: "MB", text: "Manitoba", value: "MB" },
                  { key: "NB", text: "New Brunswick", value: "NB" },
                  { key: "NL", text: "Newfoundland and Labrador", value: "NL" },
                  { key: "NS", text: "Nova Scotia", value: "NS" },
                  { key: "ON", text: "Ontario", value: "ON" },
                  { key: "PE", text: "Prince Edward Island", value: "PE" },
                  { key: "QC", text: "Quebec", value: "QC" },
                  { key: "SK", text: "Saskatchewan", value: "SK" },
                  { key: "NT", text: "Northwest Territories", value: "NT" },
                  { key: "NU", text: "Nunavut", value: "NU" },
                  { key: "YT", text: "Yukon", value: "YT" },
                ]}
              />
              <Form.Select
                label="Verify Holidays Conflicts for:"
                onChange={(e, { value }) => setVerifyHolidays(value)}
                multiple
                clearable
                options={[
                  { key: "federal", text: "Federal", value: "Federal" },
                  { key: "provincial_on", text: "Provincial (ON)", value: "Provincial-ON" },
                  { key: "provincial_ab", text: "Provincial (AB)", value: "Provincial-AB" },
                  { key: "jewish", text: "Jewish", value: "Jewish" },
                  { key: "personal", text: "Personal", value: "Personal" },
                ]}
              />
            </Form.Group>
          </Form>
        </Segment>
        <Segment>
          <Menu size="large" pagination stackable widths={3} >
            {/* using active row as disableler even that is a string, if is not null then it is true might show warining in console ignore */}
            <Menu.Item disabled={activeRow} onClick={createEventRow}>Add Event</Menu.Item>
            <Menu.Item disabled={activeRow} onClick={updateCalendar}>Update Schedule</Menu.Item>
            {/* cancel button navigate back to /id */}
            <Menu.Item disabled={activeRow} onClick={() => navigate(`/${id}`)}>Cancel</Menu.Item>
            
          </Menu>
        </Segment>
        <Segment secondary padded>
          {
            eventsData.length > 0 ? (
              <Table celled selectable stackable textAlign="center" size="large">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.HeaderCell>Time in {timezone}</Table.HeaderCell>
                    <Table.HeaderCell>Activity</Table.HeaderCell>
                    <Table.HeaderCell>Duration</Table.HeaderCell>
                    <Table.HeaderCell>Topic</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <EventRows 
                    activeRow={activeRow} 
                    setActiveRow={setActiveRow} 
                    eventsData={eventsData} 
                    timezone={timezone}
                    setConflict={setConflict} 
                    setEventsData={setEventsData} 
                    verifyHolidays={verifyHolidays} 
                  />
                </Table.Body>
              </Table>
            ) : null
          }
        </Segment>
      </Segment>
    </Container>
  );
};

export default EditCalendar;
