import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Form, Header, Segment } from "semantic-ui-react";
import { v4 as uuidv4 } from "uuid";
import { editHoliday, getAllHolidays } from "../features/holidays/holidaySlice";

const EditHolidayForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const id = useParams().holidayID;

  const { holidaysArr } = useSelector((state) => state.holidays);

  useEffect(() => {
    dispatch(getAllHolidays());
  }, [dispatch]);

  //using find instead of filter because we only want one holiday, not an array
  const holiday = useMemo(() => {
    return holidaysArr.find((holiday) => holiday.holidayID === id);
  }, [holidaysArr, id]);

  const [holidayData, setHolidayData] = useState({
    holidayID: '',
    holidayStart: '',
    holidayEnd: '',
    holidayTitle: '',
    category: "Personal"
  });

  useEffect(() => {
    if (holiday) {
      setHolidayData({
        ...holiday,
        holidayStart: holiday.holidayStart.split("T")[0],
        holidayEnd: holiday.holidayEnd.split("T")[0],
      });
    }
  }, [holiday]);

  const { holidayID, holidayStart, holidayEnd, holidayTitle, category } = holidayData;

  const onSubmit = (e) => {
    const canSave = [
      holidayID,
      holidayStart,
      holidayEnd,
      holidayTitle,
      category,
    ].every((el) => el.length >= 1);
    if (canSave) {
      try {
        dispatch(
          editHoliday({
            holidayID,
            holidayStart,
            holidayEnd,
            holidayTitle,
            category,
          })
        );
        toast.success("Calendar successfully created");
        dispatch(getAllHolidays());
        setHolidayData({
          holidayID: uuidv4(),
          holidayStart: "",
          holidayEnd: "",
          holidayTitle: "",
          category: "Personal",
        });
        navigate("/holidays");
      } catch (error) {
        const message = error.response.data.message || error.message || error.toString();
        toast.error(message);
        console.log(message);
      }
    }
  };

  return (
    <Container>
      <Segment padded raised  size="huge">
        <Header dividing as="h2" textAlign="center"> Edit Holiday </Header>
        <Form onSubmit={onSubmit}>
          <Form.Input
            label="Title"
            placeholder="Enter Holiday Title"
            name="holidayTitle"
            value={holidayTitle}
            onChange={(e) =>
              setHolidayData({ ...holidayData, holidayTitle: e.target.value })
            }
            required
          />
          <Form.Input
            label="Start Date"
            type="date"
            name="holidayStart"
            value={holidayStart}
            onChange={(e) =>
              setHolidayData({ ...holidayData, holidayStart: e.target.value })
            }
            required
          />
          <Form.Input
            label="End Date"
            type="date"
            name="holidayEnd"
            value={holidayEnd}
            onChange={(e) =>
              setHolidayData({ ...holidayData, holidayEnd: e.target.value })
            }
            required
          />
          <Form.Select
            label="Category"
            name="category"
            value={category}
            onChange={(e, { value }) =>
              setHolidayData({ ...holidayData, category: value })
            }
            options={[
              { key: "federal", text: "Federal", value: "Federal" },
              { key: "provincial_on", text: "Provincial (ON)", value: "Provincial-ON" },
              { key: "provincial_ab", text: "Provincial (AB)", value: "Provincial-AB" },
              { key: "jewish", text: "Jewish", value: "Jewish" },
              { key: "personal", text: "Personal", value: "Personal" },
            ]}
            required
          />
          <Form.Group widths="equal">
            <Form.Button color="green" fluid size="large"> Update Holiday </Form.Button>
            <Form.Button color="red" fluid size="large" onClick={() => navigate("/holidays")}> Cancel </Form.Button>
          </Form.Group>
        </Form>
      </Segment>
    </Container>
  );
};
export default EditHolidayForm;
