import getDay from "date-fns/getDay";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { sortedIndex } from "lodash";
import { redirect } from "react-router-dom";
import logo from "../assets/FL2F-logo.png";

//jspdf onclick function
const GeneratePDF = (calendar, events, timezone, setLoading) => {
  setLoading(true);
  
  // setTimeout to allow the loading spinner to show
  setTimeout(() => {
    let doc = new jsPDF("p", "px", "a4");

    let midPage = doc.internal.pageSize.getWidth() / 2
    // rectangle border
    // doc.rect(1, 24, 400, 585);

    //pdf content
    doc.setFontSize(18);

    //header
    doc.setFontSize(24);
    // numbers are X axis and Y axis

    doc.addImage(logo, "PNG", 150, 30, 180, 75, undefined, "FAST");
    // doc.text(240, 60, `FL2F - ${calendar.title}`);

    const start_date = new Date(calendar.startdate).toUTCString().split(" ")
    const end_date = new Date(calendar.enddate).toUTCString().split(" ")

    const start_display = new Date(calendar.startdate).toLocaleString("en-GB", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    const end_display = new Date(calendar.enddate).toLocaleString("en-GB", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    
    doc.setTextColor(0, 0, 0);
    doc.text(`${calendar.title} - Tentative Schedule`, midPage, 125, null, null, 'center');
    
    doc.setTextColor(197, 233, 247);

    doc.setFontSize(18);
    doc.text(`From Lab 2 Fulfillment Workshop Training Program`, midPage, 150, null, null, 'center'
    );

    doc.setTextColor(0, 0, 0);
    doc.setFontSize(16);
    doc.text(
      `Schedule Dates: ${start_display} - ${end_display}`,
      midPage,
      170,
      null, null, 'center'
    );

    const timeZoneAbbreviated = () => {
      const { 1: tz } = new Date().toString().match(/\((.+)\)/);
    
      if (tz.includes(" ")) {
        return tz
          .split(" ")
          .map(([first]) => first)
          .join("");
      } else {
        return tz;
      }
    };
    
    const getTimezone = () => {
      if (timezone === "MyTime") {
        return timeZoneAbbreviated();
      }
      return timezone;
    }

    doc.text(
      `Times Displayed in ${getTimezone()}`,
      midPage,
      190,
      null, null, 'center'
    );


    let eventY = 210;

    doc.setFontSize(18);

    events.sort((a, b) => {
      let dateSplitA = a.startdate.split("-");
      let timeSplitA = a.starttime.split(":");
      let dateSplitB = b.startdate.split("-");
      let timeSplitB = b.starttime.split(":");
      const dateA = new Date(
        dateSplitA[0],
        dateSplitA[1] - 1,
        dateSplitA[2].split("T")[0],
        timeSplitA[0],
        timeSplitA[1],
        timeSplitA[2]
      );
      const dateB = new Date(
        dateSplitB[0],
        dateSplitB[1] - 1,
        dateSplitB[2].split("T")[0],
        timeSplitB[0],
        timeSplitB[1],
        timeSplitB[2]
      );
      return dateA - dateB;
    })

    const parseTime = (time) => {
      let split = time.split(":");
      if (split[0] === "00") {
        return "12:" + split[1] + "AM";
      } else if (split[0] < 12) {
        return split[0] + ":" + split[1] + "AM";
      } else if (split[0] === "12") {
        return split[0] + ":" + split[1] + "PM";
      } else {
        split[0] = split[0] - 12;
        return split[0] + ":" + split[1] + "PM";
      }
    };

    const parseDate = (date) => {
      if (date) {
        let split = date.split("-");
        if (split[2].includes("T")) {
          split[2] = split[2].split("T")[0];
        }
        let month = "";
        if (split[1] === "01") {
          month = "January ";
        } else if (split[1] === "02") {
          month = "February";
        } else if (split[1] === "03") {
          month = "March";
        } else if (split[1] === "04") {
          month = "April";
        } else if (split[1] === "05") {
          month = "May";
        } else if (split[1] === "06") {
          month = "June";
        } else if (split[1] === "07") {
          month = "July";
        } else if (split[1] === "08") {
          month = "August";
        } else if (split[1] === "09") {
          month = "September";
        } else if (split[1] === "10") {
          month = "October";
        } else if (split[1] === "11") {
          month = "November";
        } else if (split[1] === "12") {
          month = "December";
        }

        let removeTime = split[2].split("T")[0];
        return `${month} ${removeTime}, ${split[0]}`;
      }
    };

    let offset = (date) => {
      if (new Date(date).getTimezoneOffset() >= 0) {
        date = new Date(new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60000)
        return (date);
      }
      return new Date(date);
    }

    const dayOfTheWeek = (date) => {
      let day = getDay(offset(date));
      if (day === 1) {
        return "Monday";
      } else if (day === 2) {
        return "Tuesday";
      } else if (day === 3) {
        return "Wednesday";
      } else if (day === 4) {
        return "Thursday";
      } else if (day === 5) {
        return "Friday";
      } else if (day === 6) {
        return "Saturday";
      } else if (day === 0) {
        return "Sunday";
      }
    };

    const getFullDate = (date) => {
      return `${dayOfTheWeek(date)}, ${parseDate(date)}`
    };

    const splitEvents = (events) => {
      let result = []
      let newArr = [];
      for (let i = 0; i < events.length; i++) {
        if (newArr.length !== 0) {
          if (events[i].startdate === newArr[0].startdate) {
            newArr.push(events[i])
          } else {
            result.push(newArr);
            newArr = [];
            newArr.push(events[i])
          }
        } else {
          newArr.push(events[i])
        }
      }
      result.push(newArr);
      return result;
    }

    const checkDST = (date = new Date()) => {
      let jan = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
      let jul = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
      return Math.max(jan, jul) !== date.getTimezoneOffset();
    }

    const adjustTimezone = (time, timezone) => {
      let split = time.split(":");
      let offset = 0;
      if (timezone === "PT") {
        offset = -1;
      } else if (timezone === "MT") {
        offset = 0;
      } else if (timezone === "CT") {
        offset = 1;
      } else if (timezone === "ET") {
        offset = 2;
      } else if (timezone === "AT") {
        offset = 3;
      } else if (timezone === "MyTime") {
        offset = ((new Date().getTimezoneOffset() / 60) * -1) + (checkDST() ? 6 : 7) 
      }
      let utc = new Date(2023, 0, 16, split[0], split[1], split[2]).getTime();
      let result = new Date(utc + 3600000 * offset);
      return result.toString().split(" ")[4];
    };

    let split = splitEvents(events)
    for (let i = 0; i < split.length; i++) {
      const createRows = () => {
        let rows = [];
        for (let j = 0; j < split[i].length; j++) {
        let start = parseTime(adjustTimezone(split[i][j].starttime, timezone))
        let end = parseTime(adjustTimezone(split[i][j].endtime, timezone))
        let time = () => {
          if (end === "" || start === end) {
            return `Available at ${start}`
          } else {
            return `${start} - ${end}`
          }
        }
        const date = split[i][j].startdate.split("T")[0];
          rows.push([
            getFullDate(date),
            time(),
            split[i][j].activity,
            split[i][j].duration,
            split[i][j].topic,
          ])
        }
        return rows;
      }

      doc.autoTable({
        pageBreak: 'avoid',
        rowPageBreak: 'avoid',
        styles: { fontSize: 12, halign: 'center', valign: 'middle', lineWidth: 1, cellPadding: 5 },
        bodyStyles: {halign: 'center', valign: 'middle', overflow: 'linebreak'},
        startY: doc.lastAutoTable.finalY ? doc.lastAutoTable.finalY + 10 : eventY,
        theme: 'plain',
        head: [["Date", "Time", "Activity", "Duration", "Topics"]],
        headStyles: {fillColor: [255, 255, 255], halign:'center', valign: 'middle', minCellHeight: 20},
        columnStyles: {
          0: {cellWidth: 75},
          1: {cellWidth: 60},
          2: {cellWidth: 90},
          3: {cellWidth: 60},
          4: {cellWidth: 101.46},
        },

        body: createRows(),
        didDrawCell: function (hookData) {
          if (hookData.section === 'body') {
            if (hookData.row.raw[2].includes("Completion")) {
              for (const cell of Object.values(hookData.row.cells)) {
                cell.styles.fillColor = [153, 226, 253];
              }
            } else if (hookData.row.raw[4] === "") {
              for (const cell of Object.values(hookData.row.cells)) {
                cell.styles.fillColor = [252, 229, 205];
              }
            } else if (hookData.row.raw[2].includes("Coach")) {
              for (const cell of Object.values(hookData.row.cells)) {
                cell.styles.fillColor = [0, 170, 214];
              }
            } else if (hookData.row.raw[2].includes("Group Meeting")) {
              for (const cell of Object.values(hookData.row.cells)) {
                cell.styles.fillColor = [252, 245, 141];
              }
            } else if (hookData.row.raw[2].includes("Guest")) {
              for (const cell of Object.values(hookData.row.cells)) {
                cell.styles.fillColor = [222, 235, 246];
              }
            }
          }
        },
      })
    }

    setLoading(false);
    doc.save(`FL2F-${calendar.title}`);
  }, 0);
};

export default GeneratePDF;
