import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import scheduleService from "./scheduleService";

const initialState = {
    defaultSch: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
};


export const getDefaultSchedule = createAsyncThunk(
    "schedules/getDefault",
    async (_, thunkAPI) => {
        try {
            return await scheduleService.getDefaultSchedule();
        } catch (error) {
            const message = error.response.data.message || error.message;
            return thunkAPI.rejectWithValue(message);
        }
    }
);


const scheduleSlice = createSlice({
    name: "schedules",
    initialState,
    reducers: {
        resetState: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = "";
        },
    },

    extraReducers: (builder) => {
        builder

        .addCase(getDefaultSchedule.pending, (state) => {
            state.isLoading = true;
        })

        .addCase(getDefaultSchedule.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.defaultSch = action.payload;
        })
        .addCase(getDefaultSchedule.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
        });
    },
});

export const { reset } = scheduleSlice.actions;

export default scheduleSlice.reducer;