import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Form, Header, Menu, Message, Segment, Table } from 'semantic-ui-react';
import { v4 as uuidv4 } from "uuid";
import EventRows from '../components/EventsRow';
import { createCalendar, getAll } from "../features/calendar/calendarSlice";
import { createEvent, reset } from "../features/events/eventSlice";
import { getAllGroups } from "../features/groups/groupSlice";
import { getDefaultSchedule } from '../features/schedule/scheduleSlice';



const CreateCalendars = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { calendarsArr } = useSelector((state) => state.calendars);
  const { defaultSch, isLoading: isLoadingDef } = useSelector((state) => state.schedules);
  const { groupsArr } = useSelector((state) => state.groups);

  useEffect(() => {
    dispatch(getAllGroups());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDefaultSchedule());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAll());
  }, [dispatch]);

  //get closest monday of today date
  const getClosestMonday = () => {
    const today = DateTime.local();
    const dayOfWeek = today.weekday; // Monday - 1, ..., Sunday - 7
    const difference = dayOfWeek === 1 ? 0 : (8 - dayOfWeek);
    const closestMonday = today.plus({ days: difference });
    return closestMonday.toISODate();
  };

  const [calendar, setCalendar] = useState({
    id: uuidv4(),
    groupid: "",
    startdate: getClosestMonday(),
    enddate: "",
    title: "",
    timezone: "MT",
    province: "AB",
  });

  let { id, title, startdate, enddate, timezone, province } = calendar;

  const [eventsData, setEventsData] = useState([]);
  const [verifyHolidays, setVerifyHolidays] = useState([]);
  const [activeRow, setActiveRow] = useState(null);
  const [conflict, setConflict] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(true);

  //if end date is empty, set it to the last event date if there are events present
  useEffect(() => {
    if (eventsData.length > 0 && enddate === "") {
      const lastEvent = eventsData[eventsData.length - 1];
      setCalendar({ ...calendar, enddate: lastEvent.startdate });
    }
  }, [eventsData, enddate, calendar]);


  const checkLetters = (word) => {
    return /^\s*([a-zA-Z0-9 !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*)\s*$/.test(word);
  };

  //gets default schedule from the API
  const getDefaults = () => {
    // Helper function to calculate event start dates
    const defaultDates = (startdate, daysPassed) => {
      const date = DateTime.fromISO(startdate).plus({ days: daysPassed });
      return date.toISODate();
    };

    // Map over defaultSch to transform each event
    const defaultEvents = defaultSch.events.map(event => ({
      ...event,
      id: uuidv4(),
      startdate: defaultDates(calendar.startdate, event.startdate),
      calendarid: calendar.id,
    }));

    setEventsData(defaultEvents);
    toast.success("Default schedule added");
  };

  const createEventRow = () => {

    const newEvent = {
      id: uuidv4(),
      startdate: new Date().toISOString().split("T")[0],
      starttime: "09:00",
      endtime: "10:00",
      activity: "New Activity",
      topic: "New Topic",
      duration: "1 hour",
      calendarid: id,
    };


    setEventsData(prevEventsData => [...prevEventsData, newEvent]);
    setActiveRow(newEvent.id);
    toast.success("New event created", { autoClose: 1000 });
  };

  const deleteAllRows = () => {
    if (eventsData.length > 0) {
      setEventsData([]);
      setActiveRow(null);
      toast.success("All events deleted");
    } else {
      toast.error("No events to delete");
    }
  }

  //validates correct formats for the calendar
  const validation = () => {
    if (eventsData.length === 0) {
      toast.error("No events to generate schedule");
      return false;
    }

    //check if calendar startdate is not monday with luxon
    const start = DateTime.fromISO(calendar.startdate);
    if (start.weekday !== 1) {
      toast.error("Start date must be a Monday");
      return false;
    }

    const titleExists = calendarsArr.some(cal => cal.title === calendar.title);
    if (titleExists) {
      toast.error("Title already exists, please enter a different title");
      return false;
    }

    if (calendar.title === "" || !checkLetters(calendar.title)) {
      toast.error("Please enter a valid title");
      return false;
    }

    if (calendar.groupid === "") {
      toast.error("Please assign a group to the calendar");
      return false;
    }

    if (conflict) {
      toast.error("Please resolve all conflicts before generating schedule");
      return false;
    }

    return true;
  }



  const generateSchedule = async () => {

    if (!validation()) return;
    setLoading(true);

    try {
      console.log("sent calendar", calendar);

      await dispatch(createCalendar(calendar)).unwrap();
      await Promise.all(
        eventsData.map(event =>
          dispatch(createEvent(event)).unwrap()
        )
      );
      toast.success("Schedule generated successfully");
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || "Error generating schedule";
      toast.error(errorMessage);
    } finally {
      setEventsData([]);
      setActiveRow(null);
      setLoading(false);
      dispatch(reset());
      navigate("/");
    }
  };

  console.log("calendar", calendar);

  return (
    <Container>
      <Segment loading={isLoadingDef || loading} basic>
        <Header as="h1" textAlign="center"> Create Calendar Schedule </Header>
        
        <Segment>
          <Message attached="bottom" info hidden={!showMessage} onDismiss={() => setShowMessage(false)}>
            <Message.Header>Instructions</Message.Header>
            <Message.List>
              <Message.Item>Unless mention otherwise, use default values for the schedule.</Message.Item>
              <Message.Item>The start date must be a  Monday.</Message.Item>
              <Message.Item>End date gets automatically set to the last event date after adding events, however, you can change it.</Message.Item>
              <Message.Item>You must assign a group to the calendar. Go to the&nbsp; <a href="https://user-management.fl2f.ca/" rel="noreferrer" target="_blank"> User Management </a>&nbsp; to create a group.</Message.Item>
            </Message.List>
          </Message>
          <Form size="large" onSubmit={generateSchedule}>
            <Form.Group widths="equal">
              <Form.Input
                label="Calendar ID"
                placeholder="Calendar ID"
                name="id"
                value={id}
                readOnly
              />
              <Form.Dropdown
                label="Group"
                placeholder="Group"
                name="groupid"
                value={calendar.groupid}
                onChange={(e, { value }) => setCalendar({ ...calendar, groupid: value })}
                selection
                options={groupsArr.map(group => ({ key: group.id, text: group.groupname, value: group.id }))}
                required
              />
            </Form.Group>
            <Form.Input
              label="Title"
              placeholder="Title"
              name="title"
              value={title}
              onChange={(e) => setCalendar({ ...calendar, [e.target.name]: e.target.value })}
              required
            />
            <Form.Group widths="equal">

              <Form.Input
                label="Start Date"
                title="Start date must be a Monday"
                type="date"
                name="startdate"
                value={startdate}
                onChange={(e) => setCalendar({ ...calendar, [e.target.name]: e.target.value })}
                required
              />
              <Form.Input
                label="End Date"
                type="date"
                name="enddate"
                value={enddate}
                onChange={(e) => setCalendar({ ...calendar, [e.target.name]: e.target.value })}
                required
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Dropdown
                label="Province"
                placeholder="Province"
                name="province"
                value={province}
                onChange={(e, { value }) => setCalendar({ ...calendar, province: value })}
                selection
                options={[
                  { key: "AB", text: "Alberta", value: "AB" },
                  { key: "BC", text: "British Columbia", value: "BC" },
                  { key: "MB", text: "Manitoba", value: "MB" },
                  { key: "NB", text: "New Brunswick", value: "NB" },
                  { key: "NL", text: "Newfoundland and Labrador", value: "NL" },
                  { key: "NS", text: "Nova Scotia", value: "NS" },
                  { key: "ON", text: "Ontario", value: "ON" },
                  { key: "PE", text: "Prince Edward Island", value: "PE" },
                  { key: "QC", text: "Quebec", value: "QC" },
                  { key: "SK", text: "Saskatchewan", value: "SK" },
                  { key: "NT", text: "Northwest Territories", value: "NT" },
                  { key: "NU", text: "Nunavut", value: "NU" },
                  { key: "YT", text: "Yukon", value: "YT" },
                ]}
                required
              />
              <Form.Select
                label="Verify Holidays Conflicts for:"
                onChange={(e, { value }) => setVerifyHolidays(value)}
                multiple
                clearable
                options={[
                  { key: "federal", text: "Federal", value: "Federal" },
                  { key: "provincial_on", text: "Provincial (ON)", value: "Provincial-ON" },
                  { key: "provincial_ab", text: "Provincial (AB)", value: "Provincial-AB" },
                  { key: "jewish", text: "Jewish", value: "Jewish" },
                  { key: "personal", text: "Personal", value: "Personal" },
                ]}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Button
                primary
                fluid
              > Generate Schedule </Form.Button>
              <Form.Button
                secondary
                fluid
                onClick={() => navigate("/")}
              > Cancel </Form.Button>
            </Form.Group>
          </Form>
        </Segment>
        <Segment>
          <Menu size="large" pagination stackable widths={3} >
            {/* using active row as disableler even that is a string, if is not null then it is true might show warining in console ignore */}
            <Menu.Item disabled={activeRow} onClick={() => getDefaults()}>Use Default Schedule</Menu.Item>
            <Menu.Item disabled={activeRow} onClick={() => createEventRow()}>Add Event</Menu.Item>
            <Menu.Item disabled={activeRow} onClick={() => deleteAllRows()}>Delete All Events</Menu.Item>
          </Menu>
        </Segment>
        <Segment secondary padded>
          {
            eventsData.length > 0 ? (
              <Table celled selectable stackable textAlign="center" size="large">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.HeaderCell>Time in {timezone}</Table.HeaderCell>
                    <Table.HeaderCell>Activity</Table.HeaderCell>
                    <Table.HeaderCell>Duration</Table.HeaderCell>
                    <Table.HeaderCell>Topic</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <EventRows
                    activeRow={activeRow}
                    setActiveRow={setActiveRow}
                    eventsData={eventsData}
                    timezone={timezone}
                    setConflict={setConflict}
                    setEventsData={setEventsData}
                    verifyHolidays={verifyHolidays}
                  />
                </Table.Body>
              </Table>
            ) : null
          }
        </Segment>
      </Segment>
    </Container>
  );
};
export default CreateCalendars;
