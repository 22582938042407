import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteHoliday,
  getAllHolidays,
} from "../features/holidays/holidaySlice";

const HolidayConfirm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const id = useParams().holidayID;

  // const receipt = useSelector((state) => {
  //   return state.receipts.receiptsArr.filter((receipt) => receipt._id === id);
  // });

  const onCancel = () => {
    navigate(`/holidays`);
  };
  const onDelete = () => {
    dispatch(deleteHoliday(id));
    dispatch(getAllHolidays());
    navigate("/holidays");
  };

  return (
    <main className="confirm">
      <h1>Are you sure you want to delete?</h1>
      <section className="actions">
        <button className="btn btn-block btn-danger" onClick={onDelete}>
          Delete
        </button>
        <button className="btn btn-block btn-cancel" onClick={onCancel}>
          Cancel
        </button>
      </section>
    </main>
  );
};
export default HolidayConfirm;
