import { useSelector } from "react-redux";
import { Navigate, Outlet } from 'react-router-dom';

// This component is used to protect routes that require authentication
// It checks if the user is logged in and if the user's role is allowed to access the route
// <Outlet /> is used to render the child routes of the current route that is the routes that are nested under the current route
// ensure that the user is globally authenticated insead of checking the user's role in each route
const PrivateRoutes = () => {
    const { user, isError } = useSelector((state) => state.auth);
    
    if (isError) {
        // Redirect to login if there is an error
        return <Navigate to="/login" replace />;
    }

    if (user?.role === "admin" || user?.role === "facilitator") {
        return <Outlet />;
    } else {
        // Redirect to a default page if the user's role is not recognized
        return <Navigate to="/login" replace />;
    }
};

export default PrivateRoutes;
