import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import eventService from "./eventService";

const initialState = {
  eventsArr: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Create new Event
export const createEvent = createAsyncThunk(
  "events/create",
  async (eventData, thunkAPI) => {
    try {
      // should also pass in cookie informarion
      return await eventService.createEvent(eventData);
    } catch (error) {
      const message = error.response.data.message || error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// GET All Events
export const getAllEvents = createAsyncThunk(
  "events/getAll",
  async (_, thunkAPI) => {
    try {
      // should also pass in cookie informarion
      return await eventService.getAllEvents();
    } catch (error) {
      const message = error.response.data.message || error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// GET All Events From Calendar
export const getAllEventsFromCalendar = createAsyncThunk(
  "events/getAllFromCalendar",
  async (calendarID, thunkAPI) => {
    try {
      // should also pass in cookie informarion
      return await eventService.getAllEventsFromCalendar(calendarID);
    } catch (error) {
      const message = error.response.data.message || error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Get individual Event
export const getOneEvent = createAsyncThunk(
  "events/getOne",
  async (id, eventID, thunkAPI) => {
    try {
      return await eventService.getOneEvent(id, eventID);
    } catch (error) {
      const message =
        error.response.data.message || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update/Edit Event
export const editEvent = createAsyncThunk(
  "events/edit",
  async (eventData, thunkAPI) => {
    const { calendarid } = eventData;
    
    try {
      return await eventService.editEvent(calendarid, eventData);
    } catch (error) {
      const message =
        error.response.data.message || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// delete Event
export const deleteEvent = createAsyncThunk(
  "events/delete",
  async (eventData, thunkAPI) => {
    console.log("eventData from delete slice", eventData);
    try {
      return await eventService.deleteEvent(eventData);
    } catch (error) {
      const message =
        error.response.data.message || error.message || error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// delete All Events From a specific calendar
export const deleteAllEventsFromCalendar = createAsyncThunk(
  "events/deleteAllForCalendar",
  async (calendarID, thunkAPI) => {
    try {
      return await eventService.deleteAllEventsFromCalendar(calendarID);
    } catch (error) {
      const message =
        error.response.data.message || error.message || error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const eventSlice = createSlice({
  name: "event",
  initialState,
  // this will delete everything in the events array
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      // create Event
      .addCase(createEvent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createEvent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.eventsArr.push(action.payload);
      })
      .addCase(createEvent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      //Get All
      .addCase(getAllEvents.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllEvents.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = "";
        state.eventsArr = action.payload;
      })
      .addCase(getAllEvents.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        state.eventsArr = [];
      })

      //get events from calendar
      .addCase(getAllEventsFromCalendar.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllEventsFromCalendar.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.eventsArr = action.payload;
      })
      .addCase(getAllEventsFromCalendar.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.eventsArr = [];
      })

      //Get One
      .addCase(getOneEvent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOneEvent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.eventsArr = action.payload;
      })
      .addCase(getOneEvent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.eventsArr = [];
      })

      // Update / Edit
      .addCase(editEvent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editEvent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.eventsArr = state.eventsArr.map((event) =>
          event.id === action.payload.id ? action.payload : event
        );
      })
      .addCase(editEvent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      //Delete
      .addCase(deleteEvent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteEvent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.eventsArr = state.eventsArr.filter(
          (event) => event.eventID !== action.payload.id
        );
      })
      .addCase(deleteEvent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      //Delete ALl from Calendar
      .addCase(deleteAllEventsFromCalendar.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAllEventsFromCalendar.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.eventsArr = state.eventsArr.filter(
          (event) => event.calendarID !== action.payload.id
        );
      })
      .addCase(deleteAllEventsFromCalendar.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = eventSlice.actions;

export default eventSlice.reducer;
