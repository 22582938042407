import axios from "axios";


let API_URL = "";
if (process.env.NODE_ENV !== "production") {
  API_URL = "http://localhost:5000/api/calendars/"; // lets us use the backend server in local development
} else {
  API_URL = "/api/calendars/"
}

let EVENT_API_URL = "";
if (process.env.NODE_ENV !== "production") {
  EVENT_API_URL = "http://localhost:5000/api/events/"; // lets us use the backend server in local development
} else {
  EVENT_API_URL = "/api/events/"
}

// may also want to take in cookie validation
const createEvent = async (eventData) => {
  const { data } = await axios.post(`${EVENT_API_URL}`, eventData);
  // console.log("data from service", data)
  return data;
};

// GET all events
const getAllEvents = async () => {
  const { data } = await axios.get(`${EVENT_API_URL}`);
  return data;
};
// GET all events from calendar
const getAllEventsFromCalendar = async (calendarID) => {
  const { data } = await axios.get(`${API_URL}${calendarID}/events/`);
  return data;
};

// GET one event
const getOneEvent = async (id, eventID) => {
  const { data } = await axios.get(`${API_URL}${id}/events/${eventID}`);
  return data;
};

//Edit event
const editEvent = async (calendarID, eventData) => {
  const { data } = await axios.put(
    `${API_URL}${calendarID}/events/`,
    eventData
  );
  return data;
};

//Delete event
const deleteEvent = async (eventData) => {
  const { calendarid, id } = eventData;
  const { data } = await axios.delete(
    `${API_URL}${calendarid}/events/${id}`,
    eventData
  );
  console.log("data from deleted event", data)
  return data;
};

//Delete All Events From Specific calendar
const deleteAllEventsFromCalendar = async (calendarID) => {
  const { data } = await axios.delete(`${API_URL}${calendarID}/events/`);
  return data;
};

const calendarService = {
  createEvent,
  getAllEvents,
  getAllEventsFromCalendar,
  getOneEvent,
  editEvent,
  deleteEvent,
  deleteAllEventsFromCalendar,
};

export default calendarService;
