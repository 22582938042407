import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Container, Header, Modal, Popup, Segment } from "semantic-ui-react";
import { deleteCalendar } from "../features/calendar/calendarSlice";
import { deleteAllEventsFromCalendar } from "../features/events/eventSlice";

const Confirm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const onCancel = () => {
    navigate(`/${id}`);
  };

  const onDelete = () => {
    dispatch(deleteAllEventsFromCalendar(id));
    dispatch(deleteCalendar(id));
    navigate("/");
  };

  return (
    <Container style={{ width: "700px" }}>
      <Modal open={true} onClose={onCancel} size="large" >
        <Header textAlign="center" content="Are you sure you want to delete this calendar?" />
        <Modal.Content>
          <Segment basic textAlign="center">
            <Button.Group fluid>
              <Popup
                content={<Button size="huge" onClick={onDelete}>This action cannot be undone!</Button>}
                trigger={<Button size="huge" color="red" content="Delete" />}
                on="click"
                position="top center"
                flowing
              />
              <Button size="huge" color="green" onClick={onCancel}>Cancel</Button>
            </Button.Group>
          </Segment>
        </Modal.Content>
      </Modal>
    </Container>
  );
};
export default Confirm;
