import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Image, Menu } from "semantic-ui-react";
import logo from "../assets/FL2F-logo.png";
import { logout, reset } from "../features/auth/authSlice";
import { setActiveItem } from "../features/menu/menuSlice";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { activeItem } = useSelector((state) => state.menu);

  const handleActiveItem = (e, { name }) => dispatch(setActiveItem(name));

  const onLogout = () => {
    dispatch(logout());
    dispatch(reset());
    navigate("/login");
  };

  if (location.pathname === "/login" || location.pathname.includes('/view-calendar/')) return null;

  console.log(location.pathname)
  
  return (
    <Menu size="huge">
      <Menu.Item as={Link} to="/" onClick={handleActiveItem}>
        <Image src={logo} size="small" />
      </Menu.Item>
      <Menu.Menu position="right">
        <Menu.Item as={Link} to="/holidays" name="Holidays" active={activeItem === 'Holidays'} onClick={handleActiveItem}>
          Holidays
        </Menu.Item>
        <Menu.Item name="Logout" onClick={onLogout}>
          Logout
        </Menu.Item>
      </Menu.Menu> 
  </Menu>
  );
};
export default Navbar;
