import { DateTime } from 'luxon';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Form, Table } from 'semantic-ui-react';
import { getAllHolidays } from "../features/holidays/holidaySlice";

// Mapping of timezones to their respective timezone strings
const timezoneMapping = {
  MT: 'America/Denver',
  PT: 'America/Los_Angeles',
  CT: 'America/Chicago',
  ET: 'America/New_York',
  AT: 'America/Halifax'
};

// Function to convert a time string to a timezone
const convertTimezone = (date, time, targetTimezone) => {
  const dateTimeInMT = DateTime.fromISO(`${date}T${time}`, { zone: 'America/Denver' });
  const dateTimeInTargetZone = dateTimeInMT.setZone(timezoneMapping[targetTimezone]);
  return dateTimeInTargetZone.toFormat('HH:mm:ss');
};


const checkLetters = (word) => {
  return /^\s*([a-zA-Z0-9 !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*)\s*$/.test(word);
};

const EventRows = ({ activeRow, setActiveRow, eventsData, setEventsData, timezone, verifyHolidays, setConflict }) => {
  const dispatch = useDispatch();
  const [savedRow, setSavedRow] = useState(null);
  const { holidaysArr } = useSelector((state) => state.holidays);

  useEffect(() => {
    dispatch(getAllHolidays());
  }, [dispatch]);

  //scroll to the active edit row form
  useEffect(() => {
    if (activeRow) {
      // Ensure the document has been rendered and the element exists
      const rowElement = document.getElementById(`edit-${activeRow}`);
      if (rowElement) {
        rowElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    }
  }, [activeRow]);

  // Function to handle changes to event data from the edit row form
  const handleEventChange = useCallback((eventID, field, value) => {
    setEventsData(currentEvents =>
      currentEvents.map(event =>
        event.id === eventID ? { ...event, [field]: value } : event
      )
    );
  }, [setEventsData]);

  // Function to handle canceling changes to an event it revert changes or remove the row if it's a new row
  const onCancel = useCallback((e) => {
    e.preventDefault();
    if (savedRow === null) { // Check if it's a new row (no saved data)
      setEventsData(currentEvents => currentEvents.filter(event => event.id !== activeRow));
      toast.info('Event discarded', { autoClose: 1000 });
    } else {
      // Revert changes for existing rows
      setEventsData(currentEvents => currentEvents.map(event => event.id === activeRow ? { ...savedRow } : event));
      toast.info('Changes reverted', { autoClose: 1000 });
    }
    setActiveRow(null);
    setSavedRow(null); // Reset savedRow after canceling
  }, [activeRow, savedRow, setEventsData, setActiveRow]);


  // Ensure that savedRow is correctly managed. Your current implementation seems correct but review it if necessary:
  const handleEditClick = useCallback((eventID) => {
    const eventToEdit = eventsData.find(event => event.id === eventID);
    if (eventToEdit) {
      setSavedRow({ ...eventToEdit });
      setActiveRow(eventID);
    }
  }, [eventsData, setActiveRow, setSavedRow]);

  // Function to handle saving the event data
  const onSaveEvent = useCallback((e) => {
  
    const { activity, duration } = eventsData.find(event => event.id === activeRow) || {};
  
    if (checkLetters(activity) && checkLetters(duration)) {
      toast.success('Event saved successfully', { autoClose: 1000 });
      setActiveRow(null);
      setSavedRow(null);
    } else {
      toast.error('Activity or Duration contains invalid characters. Please correct and try again.', { autoClose: 3000 });
    }
  }, [activeRow, eventsData, setActiveRow]);
  

  // Function to handle the delete event where it removes the current row from the events data
  const onDelete = useCallback((e) => {
    e.preventDefault();
    setEventsData(currentEvents =>
      currentEvents.filter(event => event.id !== activeRow)
    );
    setActiveRow(null);
    toast.success('Event deleted', { autoClose: 1000 });
  }, [activeRow, setEventsData, setActiveRow]);


  // Function to check for holiday conflicts
  const checkHolidayConflict = useCallback((eventDate) => {
    return holidaysArr.some(holiday => {
      const holidayDate = holiday.holidayStart.split('T')[0];
      return verifyHolidays.includes(holiday.category) && holidayDate === eventDate;
    });
  }, [holidaysArr, verifyHolidays]);

  useEffect(() => {
    const hasConflict = eventsData.some(event => {
      return checkHolidayConflict(event.startdate);
    });
    setConflict(hasConflict);
  }, [eventsData, holidaysArr, checkHolidayConflict, setConflict]);

  return (
    <>
      {eventsData.map((event) => (
        // Fragment is used to group the EventRow and EventDetailsRow components together with a unique key
        <Fragment key={event.id}>
          <EventRow
            event={event} 
            isActive={activeRow === event.id} 
            onClick={() => handleEditClick(event.id)} 
            timezone={timezone}
            isConflict={checkHolidayConflict(event.startdate)}
            isDisabled={activeRow !== null && activeRow !== event.id}
          />
          {activeRow === event.id && 
          <EventDetailsRow 
            setActiveRow={setActiveRow}
            timezone={timezone}
            event={event} handleEventChange={handleEventChange} 
            onDelete={onDelete}
            onSaveEvent={onSaveEvent}
            onCancel={onCancel} />}
        </Fragment>
      ))}
    </>
  );
};

// EventRow component displays a single event in a table row using memoization to prevent unnecessary re-renders
const EventRow = React.memo(({ event, isActive, onClick, isConflict, isDisabled }) => {
  const startTime = DateTime.fromISO(`${event.startdate}T${event.starttime}`, { zone: 'America/Denver' }).toFormat('HH:mm');
  const endTime = DateTime.fromISO(`${event.startdate}T${event.endtime}`, { zone: 'America/Denver' }).toFormat('HH:mm');
  
  const eventDate = DateTime.fromISO(`${event.startdate}T${event.starttime}`, { zone: 'America/Denver' }).toFormat('ccc, MMM dd, yyyy');

  return (
    <Table.Row active={isActive} onClick={onClick} warning={isConflict} disabled={isDisabled}>
      <Table.Cell singleLine>{eventDate}</Table.Cell>
      <Table.Cell singleLine>{`${startTime} - ${endTime}`}</Table.Cell>
      <Table.Cell>{event.activity}</Table.Cell>
      <Table.Cell>{event.duration}</Table.Cell>
      <Table.Cell>{event.topic}</Table.Cell>
    </Table.Row>
  );
});

// EventDetailsRow component displays a form for editing a single event in a table row using memoization to prevent unnecessary re-renders
const EventDetailsRow = React.memo(({ event, handleEventChange, onCancel, onDelete, setActiveRow, timezone, onSaveEvent }) => {

  return (
    <Table.Row id={`edit-${event.id}`}>
      <Table.Cell colSpan={5}>
        <Form size="small" onSubmit={onSaveEvent}>
          <Form.Group widths="equal">
            <Form.Input
              type='date'
              label="Date"
              placeholder="date"
              name="startdate"
              value={event.startdate}
              onChange={(e) => handleEventChange(event.id, "startdate", e.target.value)}
            />
            <Form.Input
              type='time'
              label="Start Time"
              placeholder="Start Time"
              name="starttime"
              value={convertTimezone(event.startdate, event.starttime, timezone)}
              onChange={(e) => handleEventChange(event.id, "starttime", e.target.value)}
            />
            <Form.Input
              type='time'
              label="End Time"
              placeholder="End Time"
              name="endtime"
              value={convertTimezone(event.startdate, event.endtime, timezone)}
              onChange={(e) => handleEventChange(event.id, "endtime", e.target.value)}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              label="Activity"
              placeholder="Activity"
              name="activity"
              value={event.activity}
              onChange={(e) => handleEventChange(event.id, "activity", e.target.value)}
            />
            <Form.Input
              label="Topic"
              placeholder="Topic"
              name="topic"
              value={event.topic}
              onChange={(e) => handleEventChange(event.id, "topic", e.target.value)}
            />
            <Form.Input
              label="Duration"
              placeholder="Duration"
              name="duration"
              value={event.duration}
              onChange={(e) => handleEventChange(event.id, "duration", e.target.value)}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Button title="Saves changes" fluid color="green">Save</Form.Button>
            <Form.Button title="Delets selected event" fluid color ="red" onClick={onDelete}>Delete</Form.Button>
            <Form.Button title="Reverts any changes" fluid color="black" onClick={onCancel}>Cancel</Form.Button>
          </Form.Group>
        </Form>
      </Table.Cell>
    </Table.Row>
  )

});

export default EventRows;