import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Container, Grid, Header, Menu, Segment, Table } from "semantic-ui-react";
import { getAllHolidays } from "../features/holidays/holidaySlice";
import "../styles/dashboard.scss";
import "../styles/holidays.scss";

// TO ADD ANOTHER YEAR OF HOLIDAYS: 
// FIND LIST OF CANADIAN FEDERAL AND PROVINCIAL HOLIDAYS AT https://canada-holidays.ca/api/v1/holidays/

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { holidaysArr, isLoading } = useSelector((state) => state.holidays);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());


  useEffect(() => {
    dispatch(getAllHolidays())
  }, [dispatch]);

  const yearsRange = useMemo(() => {
    const years = holidaysArr.map((holiday) => new Date(holiday.holidayStart).getFullYear());
    return Array.from(new Set(years)).sort((a, b) => a - b);
  }, [holidaysArr]);

  const filteredHolidays = useMemo(() => 
  holidaysArr.filter((holiday) => new Date(holiday.holidayStart).getFullYear() === selectedYear),
  [holidaysArr, selectedYear]);


  return (
    <Container>
      <Segment loading={isLoading} basic>
        <Header as="h1" textAlign="center"> Holiday Dashboard </Header>
        <Segment>
          <Grid verticalAlign="middle"  stackable>
            <Grid.Column width={11}>
              <Menu size="large" fluid pagination widths={yearsRange.length === 0 ? 5 : yearsRange.length}>
                {
                  yearsRange.map((year, index) => (
                    <Menu.Item key={index} active={year === selectedYear} onClick={() => setSelectedYear(year)}>{year}</Menu.Item>
                  ))
                }
              </Menu>
            </Grid.Column>
            <Grid.Column width={5}>
              <Button fluid  size="big" color="green" onClick={() => navigate("/holidays/create")}>Add Holiday</Button>
            </Grid.Column>
          </Grid>
        </Segment>
        <Segment secondary padded>
          <Table stackable textAlign="center" singleLine compact size="large">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Category</Table.HeaderCell>
                <Table.HeaderCell>Title</Table.HeaderCell>
                <Table.HeaderCell>Start</Table.HeaderCell>
                <Table.HeaderCell>End</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                filteredHolidays.length > 0 ? (
                  filteredHolidays.sort((a, b) => new Date(a.holidayStart) - new Date(b.holidayStart)).map((holiday) => (
                    <Table.Row key={holiday.holidayID}>
                      <Table.Cell>{holiday.category}</Table.Cell>
                      <Table.Cell title={holiday.holidayTitle}>{holiday.holidayTitle.length > 22 ? `${holiday.holidayTitle.slice(0, 22)}` : holiday.holidayTitle }</Table.Cell>
                      <Table.Cell>{holiday.holidayStart.split("T")[0]}</Table.Cell>
                      <Table.Cell>{holiday.holidayEnd.split("T")[0]}</Table.Cell>
                      <Table.Cell>
                        <Button.Group fluid size="small">
                          <Button color="blue" onClick={() => navigate(`/holidays/${holiday.holidayID}/edit`)}>Edit</Button>
                          <Button color="red" onClick={() => navigate(`/holidays/${holiday.holidayID}/confirm`)}>Delete</Button>
                        </Button.Group>
                      </Table.Cell>
                    </Table.Row>
                  ))
                ) : (
                  <Table.Row>
                    <Table.Cell colSpan="5">No holidays found for the selected year</Table.Cell>
                  </Table.Row>
                )
              }
            </Table.Body>
          </Table>
        </Segment>  
      </Segment>
    </Container>
  );
};
export default Dashboard;
